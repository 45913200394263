// `getScriptWithOpts` & `cacheScript() both are the alternatives to the `getScript()`.
// See: https://api.jquery.com/jquery.getscript/.

//`getScriptWithOpts` also accepts the additional options.
$.getScriptWithOpts = function( url, options, success ) {
  // Allow user to set any option except for dataType, url & success
  options = $.extend( options || {}, {
    url: url,
    dataType: "script",
    success: success
  });

  // Use $.ajax() since it is more flexible than $.getScriptWithOpts
  // Return the jqXHR object so we can chain callbacks
  return jQuery.ajax( options );
};

// `cachedScript()` fetches and caches the script, whereas `getScript()` doesn't cache but fetches everytime.
$.cachedScript = function( url, options ) {
  // Allow user to set any option except for dataType, cache, and url
  options = $.extend( options || {}, {
    dataType: "script",
    cache: true,
    url: url
  });

  // Use $.ajax() since it is more flexible than $.getScript
  // Return the jqXHR object so we can chain callbacks
  return jQuery.ajax( options );
};
